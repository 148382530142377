<template>
  <div class="ReportGeneralResume innerFeedContent" :class="{ loading: loading }">
    <div dcard dradius>
      <div class="ResumeContent">
        <div left aside>
          <div class="ResumeBlock" dradius>
            <div label skeleton>
              <span>{{ Go.date_locale(startDate) }}</span>
            </div>
          </div>
        </div>
        <div right aside>
          <div class="ResumeBlock">
            <div label skeleton>
              <span>{{ Go.date_locale(endDate) }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="ResumeContent">
        <div left aside>
          <div class="ResumeBlock" dradius>
            <div number>
              <span class="numIcon"><Icon name="dollar" /></span>
              <span skeleton>{{ Go.number_format(totalSalesDollars, 2) }}</span>
            </div>
            <div label>
              <span>{{ $locale["total_sales_dollars"] }}</span>
            </div>
          </div>
          <CircleProgress
            skeleton
            :progress="salesSubscriptionsPercent"
            :size="72"
            color="green"
            :label="`<div>${Go.number_format(salesSubscriptionsPercent)} %</div>`"
          />
        </div>
        <div right aside>
          <div class="ResumeBlock" dradius>
            <div number>
              <span class="numIcon"><Icon name="coins" /></span>
              <span skeleton>{{ Go.number_format(totalSalesCredits, 0) }}</span>
            </div>
            <div label>
              <span>{{ $locale["total_sales_credits"] }}</span>
            </div>
          </div>
          <CircleProgress skeleton :progress="salesCreditsPercent" :size="72" :label="`<div>${Go.number_format(salesCreditsPercent)} %</div>`" />
        </div>
      </div>
      <div class="ResumeContent">
        <div left aside>
          <div class="ResumeBlock" dradius>
            <div number skeleton>
              <span>{{ Go.number_format(totalSubscriptions, 0) }}</span>
            </div>
            <div label skeleton>
              <span>{{ $locale["total_subscriptions"] }}</span>
            </div>
          </div>
        </div>
        <div right aside>
          <div class="ResumeBlock">
            <div number skeleton>
              <span>{{ Go.number_format(totalPosts, 0) }}</span>
            </div>
            <div label skeleton>
              <span>{{ $locale["total_posts"] }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: function() {
    return {
      loading: false,
      totalSalesDollars: 0,
      totalSalesCredits: 0,
      totalSubscriptions: 0,
      salesSubscriptionsPercent: 0,
      salesCreditsPercent: 0,
      totalPosts: 0,
      statistics: {
        startDate: "",
        endDate: "",
      },
    };
  },
  methods: {
    getResume: async function() {
      this.loading = true;
      try {
        this.query = Go.objectToQuery(this.$route.query);
        this.req = await this.api.get(`admin/statistics?${this.query}`);
        this.resumeSuccess(this.req.data);
      } catch (error) {
        this.alert(Go.getErrorMessage(error));
      }
      this.loading = false;
    },
    resumeSuccess: function({ statistics = {} }) {
      this.statistics = statistics;
      this.totalPosts = statistics.totalPosts;
      this.totalSalesDollars = statistics.totalSalesOnDollars;
      this.totalSalesCredits = statistics.totalSalesOnCredits;
      this.totalSubscriptions = statistics.totalSubscriptions;
      this.salesSubscriptionsPercent = statistics.salesSubscriptionsPercent;
      this.salesCreditsPercent = statistics.salesCreditsPercent;
      this.$store.state.admin.salesByCreatorList.statistics = statistics;
    },
  },
  mounted: function() {
    this.getResume();
  },
  computed: {
    startDate: function() {
      return this.$route.query.startDate || this.statistics.startDate;
    },
    endDate: function() {
      return this.$route.query.endDate || this.statistics.endDate;
    },
  },
  watch: {
    "$route.query": function() {
      this.getResume();
    },
  },
};
</script>

<style lang="scss">
.ReportGeneralResume {
  .ResumeContent {
    display: flex;
    align-items: center;
    flex-direction: column;
    font-size: 90%;
  }

  .ResumeContent [aside] {
    position: relative;
    flex: 1;
    text-align: left;
    display: flex;
    justify-content: space-between;
    width: 100%;
    border-bottom: solid 1px $alto;
  }

  .circleProgress {
    padding: $mpadding/2;
  }

  .ResumeBlock {
    padding: $mpadding;
  }

  .ResumeBlock [label] {
    opacity: 0.8;
  }

  .ResumeBlock [number] {
    font-size: 180%;
    font-family: $third_font_bold;
    display: flex;
    align-items: center;
  }

  .ResumeBlock .numIcon {
    margin: 0 $mpadding/2 0 0;
    opacity: 0.8;
  }

  .ResumeBlock [label] {
    font-size: 120%;
    font-family: $third_font;
  }

  @include screen($tablet_width) {
    .ResumeContent {
      flex-direction: row;
      font-size: 100%;
    }

    .ResumeContent [aside] {
      flex: 0.5;
    }

    .ResumeContent [aside]:first-child::after {
      content: "";
      position: absolute;
      right: 0;
      top: $mpadding;
      bottom: $mpadding;
      width: 1px;
      background-color: rgba($alto, 0.5);
    }
  }
}
</style>
